import type DataGridControl from './DataGridControl.ts';
import { InjectionKeys } from 'o365-utils';
import { defineComponent, inject, markRaw, onMounted, onBeforeUnmount, onUpdated, ref, h } from 'vue';
import { Debouncer } from 'o365-utils';
import DataColumn from './DataGrid.DataColumn.ts';

const dataGridControlKey = InjectionKeys.dataGridControlKey
declare module './DataGrid.DataColumn.ts' {
    interface DataColumn {
        autoHeightApi: typeof ColumnAutoHeightApi
    }
}
declare module './DataGridControl.ts' {
    interface DataGridControl {
        _autoHeightStore?: Record<number, Record<string, number>>
    }
}

Object.defineProperties(DataColumn.prototype, {
    'autoHeightApi': {
        get() {
            return ColumnAutoHeightApi;
            // if (this._autoHeightApi = new ColumnAutoHeightApi();
            // }== null) {
            //     this._autoHeightApi = new ColumnAutoHeightApi();
            // }
            // return this._autoHeightApi;
        }
    },
});

type AutoHeightCellCtx = {
    slots: {
        default: (bindgins: {
            target: (pElement: HTMLElement | null) => void
        }) => void
    },
};

type AutoHeightCellProps = {
    column: DataColumn,
    row: Object
};
class CellObserver {
    private _observer: ResizeObserver;
    private _elements: Map<string, any> = new Map();
    get minHeight() { return 34; }

    constructor() {
        this._observer = new ResizeObserver((entries) => {
            entries.forEach(entry => {
                const target = entry.target;
                const sizes = getElementSize(target.parentElement);
                let scrollHeight = target.scrollHeight + sizes.paddingTop + sizes.paddingBottom;
                if (scrollHeight <= this.minHeight) { scrollHeight = this.minHeight; }

                const el = this._elements.get(target.dataset.uid);
                const props = el.props;

                if (props?.row?.updateSize)
                    props?.row?.updateSize(Math.ceil(scrollHeight));
            })
        })
    }

    addElement(pElement: HTMLElement, pProps: any, uid: string) {
        this._observer.observe(pElement);
        pElement.dataset.uid = uid;
        this._elements.set(uid, { el: pElement, props: pProps })
    }



}
const observer = new CellObserver();

const elements: Map<string, any> = new Map();
const elementsIndexes: Map<string, Number> = new Map();
let cellIndex = 0;
const previousRowHeights = new Map<string, number>();

const AutoHeightCell = markRaw(defineComponent({
    name: 'AutoHeightCell',
    props: {
        column: Object,
        row: Object
    },
    setup(props: AutoHeightCellProps, ctx: AutoHeightCellCtx) {
        const dataGridControl: { value?: DataGridControl } = inject(dataGridControlKey);
        if (dataGridControl == null || dataGridControl.value == null) {
            throw new TypeError('AutoHeightCell cannot be used outside of data grid');
        }
        const cellRef: { value: HTMLElement | null } = ref(null);
        const uid = crypto.randomUUID();
        let rowStoreId = `${dataGridControl.value.id}_${props.row.index}`;

        const debouncer = new Debouncer(50);

        const cellUid = cellIndex++;


        onMounted(() => {
            if (cellRef.value) {

                cellRef.value.dataset.uid = rowStoreId;
                elementsIndexes.set(props.row.index, 34)
                elements.set(rowStoreId, { el: cellRef.value, props: props })
                // doUpdate();
            }
        });
        onUpdated(() => {
            doUpdate();
        })

        function doUpdate() {
            debouncer.clear();
            if (cellRef.value == null) { return; }
            let rowStoreId = `${dataGridControl.value.id}_${props.row.index}`;
            let previousScrollHeight = previousRowHeights.get(rowStoreId);
            let updateSize = false;
            if (cellRef.value) {
                const sizes = getElementSize(cellRef.value);
                let scrollHeight = cellRef.value.scrollHeight + sizes.paddingTop + sizes.paddingBottom;
                if (previousScrollHeight == scrollHeight) {
                    return;
                }
                previousRowHeights.set(rowStoreId, scrollHeight);
                updateSize = true;
            }
            if (updateSize || !elementsIndexes.has(props.row.index)) {
                // console.log('doesnt have id ', props.row.index, ' row id ', props.row.index)
                elementsIndexes.set(props.row.index, 34)
                debouncer.run(() => {
                    let count = 0;
                    let prevIndex = undefined;
                    for (let [key, value] of elements) {
                        count++;
                        const target = value.el;
                        const sizes = getElementSize(target.parentElement);
                        let scrollHeight = target.scrollHeight + sizes.paddingTop + sizes.paddingBottom;
                        if (scrollHeight == 0) {
                            continue;
                        }
                        if (scrollHeight <= 34) { scrollHeight = 34; }

                        const props = value.props;

                        if (value.props?.row) {
                            if (value.props.row.rowHeight != scrollHeight) {
                                if (props?.row?.updateSize) {
                                    // console.log('Try to update :', props.row.index, " ; ", ' row id ', props.row.index, " ; From ", value.props.row.rowHeight, " To ", scrollHeight)
                                    // console.log(scrollHeight, 'Height;  ', props.row.item.ID, ' ID;   ', value.props.row.rowHeight, ' value.props.row.rowHeight;')
                                    const rowEl = value.el.closest('[data-o365-rowindex]');
                                    prevIndex = props.row.index;
                                    props?.row?.updateSize(scrollHeight)
                                }
                            }
                            elementsIndexes.set(props.row.index, scrollHeight)

                        }


                    }
                })
            }


        }



        onBeforeUnmount(() => {
            debouncer.clear();
            //    cellObserver.unobserve();
            // cellObserver.destroy(); 
        });
        // return () => ctx.slots.default({target: el => cellRef.value = el});
        return () => h('div', {
            class: 'o365-autoheight-wrapper d-flex text-wrap',
            ref: el => cellRef.value = el
        }, 
            ctx.slots.default({ target: el => cellRef.value = el }),
        );
    },
}));

const rowStore = new Map<string, Record<string, number>>();

const autoHeightCellObserverStore = new Map<string, AutoHeightCellObserver>();
function getAutoHeightCellObserver(pUid: string, pDataGridControl: DataGridControl, pGetProps: () => AutoHeightCellProps, pRowStoreId: string) {
    if (!autoHeightCellObserverStore.has(pUid)) {
        autoHeightCellObserverStore.set(pUid, new AutoHeightCellObserver(pDataGridControl, pGetProps, pRowStoreId));
    }
    return autoHeightCellObserverStore.get(pUid)!;
}



class AutoHeightCellObserver {
    private _dataGridControl: DataGridControl;
    private _getProps: () => AutoHeightCellProps;
    private _element?: HTMLElement;
    private _observer: ResizeObserver;

    private _height?: number;
    private _rowStoreId: string;

    get minHeight() { return 34; }

    constructor(pDataGridControl: DataGridControl, pGetProps: () => AutoHeightCellProps, pRowStoreId: string) {
        this._dataGridControl = pDataGridControl;
        this._getProps = pGetProps;
        this._rowStoreId = pRowStoreId;
        const debouncer = new Debouncer(50);
        this._observer = new ResizeObserver((entries) => {

            // debouncer.run(() => {
            // entries.forEach(entry => {

            //     const target = entry.target;
            //     const sizes = getElementSize(target.parentElement);
            //     let scrollHeight = target.scrollHeight + sizes.paddingTop + sizes.paddingBottom;
            //     if (scrollHeight <= this.minHeight) { scrollHeight = this.minHeight; }
            //     this.storeHeight(scrollHeight);
            //     scrollHeight = this.getMaxHeight();
            //     //console.log(this._height,scrollHeight);
            //     if (this._height == scrollHeight) { return; }
            //     this._height = scrollHeight;
            //     const props = this._getProps();
            //     // Math.ceil(scrollHeight)
            //     if (props?.row?.updateSize) {
            //         props.row.updateSize(scrollHeight)
            //         // props.row.updateSize(Math.ceil(scrollHeight))
            //         console.log(Math.ceil(scrollHeight), 'Height;  ', props.row.item.ID, ' ID;   ', entry.target, ' Target;  ')



            //     }
            // });
            //});
        });
    }

    setElement(pElement: HTMLElement) {
        this._element = pElement;
    }
    observe() {
        if (this._element == null) { return; }
        // return;
        this._observer.observe(this._element);
    }
    unobserve() {
        if (this._element == null) { return; }
        // return;
        this._observer.unobserve(this._element);
        rowStore.delete(this._rowStoreId);
    }

    destroy() {
        this._observer.disconnect();
    }

    storeHeight(pHeight: number) {
        if (!rowStore.has(this._rowStoreId)) { rowStore.set(this._rowStoreId, {}) };
        const rowHeights = rowStore.get(this._rowStoreId)!;
        const colId = this._getProps().column.colId as string;
        rowHeights[colId] = pHeight;
    }

    getMaxHeight() {
        const rowHeights = rowStore.get(this._rowStoreId) ?? {};
        return Object.entries(rowHeights).reduce((maxHeight, entry) => {
            return Math.max(maxHeight, entry[1]);
        }, this.minHeight);

    }
}

const ColumnAutoHeightApi = {
    getCellComponent() { return AutoHeightCell; }
}

function getElementSize(el: HTMLElement): {
    height: number;
    width: number;
    borderTopWidth: number;
    borderRightWidth: number;
    borderBottomWidth: number;
    borderLeftWidth: number;
    paddingTop: number;
    paddingRight: number;
    paddingBottom: number;
    paddingLeft: number;
    marginTop: number;
    marginRight: number;
    marginBottom: number;
    marginLeft: number;
    boxSizing: string;
} {
    const {
        height,
        width,
        borderTopWidth,
        borderRightWidth,
        borderBottomWidth,
        borderLeftWidth,
        paddingTop,
        paddingRight,
        paddingBottom,
        paddingLeft,
        marginTop,
        marginRight,
        marginBottom,
        marginLeft,
        boxSizing,
    } = window.getComputedStyle(el);

    return {
        height: parseFloat(height || '0'),
        width: parseFloat(width || '0'),
        borderTopWidth: parseFloat(borderTopWidth || '0'),
        borderRightWidth: parseFloat(borderRightWidth || '0'),
        borderBottomWidth: parseFloat(borderBottomWidth || '0'),
        borderLeftWidth: parseFloat(borderLeftWidth || '0'),
        paddingTop: parseFloat(paddingTop || '0'),
        paddingRight: parseFloat(paddingRight || '0'),
        paddingBottom: parseFloat(paddingBottom || '0'),
        paddingLeft: parseFloat(paddingLeft || '0'),
        marginTop: parseFloat(marginTop || '0'),
        marginRight: parseFloat(marginRight || '0'),
        marginBottom: parseFloat(marginBottom || '0'),
        marginLeft: parseFloat(marginLeft || '0'),
        boxSizing,
    };
}